import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { queryClient } from '@/lib/queryClient';
import '@/lib/dayjsPlugins';
import 'react-toastify/dist/ReactToastify.css';
import ErudaLoader from './components/ErudaLoader';
import { IsSsrMobileContext } from './lib/useIsMobile';

type Props = {
  children: ReactNode;
  isSsrMobile: boolean;
};

export const AppProviders: FC<Props> = ({ children, isSsrMobile }) => {
  // This ensures that data is not shared
  // between different users and requests
  const [queryClientState] = useState(() => queryClient);

  return (
    <>
      <IsSsrMobileContext.Provider value={isSsrMobile}>
        <QueryClientProvider client={queryClientState}>
          {children}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </IsSsrMobileContext.Provider>
      <ErudaLoader isMobile={isSsrMobile} />
    </>
  );
};
