// import { loadJS } from '@ahm/common-helpers';
import { useEffect } from 'react';
import { loadJS } from '@/lib/common';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eruda: any;
  }
}

interface Props {
  isMobile: boolean;
}

export default function ErudaLoader({ isMobile }: Props) {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENABLE_ERUDA === 'true' && isMobile) {
      const src = `//cdn.jsdelivr.net/npm/eruda`;

      loadJS(src).then(() => {
        if (window.eruda && window.eruda._isInit) return;
        window.eruda.init();
      });
    }
    return () => {
      window.eruda && window.eruda._isInit && window.eruda.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return null;
}
