import type { AxiosError } from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import he from 'he';
import type { Question } from 'next-seo/lib/types';
import type {
  FormatHelpCenter,
  HelpCenter,
  HelpCenterItem,
} from '@/api/cms/help-center/types';
import type {
  FormatPolicy,
  FormatRichDescription,
} from '@/api/cms/policy/types';
import type { Category, PostTag } from '@/api/cms/posts/types';
import type { DefaultCmsDataResponse, RichDescription } from '@/api/cms/types';
import type { FormatCategory, FormatPostTag } from 'types.d/post-format';
import { sendTelegramMessage } from './telegram';

export function capitalizeString(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const formatNumber = (num = 0): string => {
  return new Intl.NumberFormat('en-US').format(num);
};

export const formatCurrency = (
  num = 0,
  locales: 'en-US' | 'vi-VN' = 'vi-VN'
): string => {
  return new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: 'VND',
    maximumSignificantDigits: 3,
  }).format(num);
};

export enum WebInAppID {
  AHA_USER = 'ahauser',
  AHA_DRIVER = 'ahadriver',
  AHA_MOVE = 'ahamove',
}

function repeat(s: string, times: number): string {
  return new Array(times + 1).join(s);
}

export const pad = (num: number, maxLength: number): string => {
  return repeat('0', maxLength - num.toString().length) + num;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadJS = (src: string, attr?: { [key: string]: any }) => {
  return new Promise((resolve, reject) => {
    const tag = document.createElement('script');
    tag.setAttribute('src', src);
    tag.setAttribute('type', 'text/javascript');
    if (attr) {
      for (const key in attr) {
        tag.setAttribute(key, attr[key]);
      }
    }
    tag.onload = resolve;

    tag.onerror = reject;

    document.head.appendChild(tag);
  });
};

export const cleanAccents = (str: string): string => {
  str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a');
  str = str.replace(/[èéẹẻẽêềếệểễ]/g, 'e');
  str = str.replace(/[ìíịỉĩ]/g, 'i');
  str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o');
  str = str.replace(/[ùúụủũưừứựửữ]/g, 'u');
  str = str.replace(/[ỳýỵỷỹ]/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, 'A');
  str = str.replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, 'E');
  str = str.replace(/[ÌÍỊỈĨ]/g, 'I');
  str = str.replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, 'O');
  str = str.replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, 'U');
  str = str.replace(/[ỲÝỴỶỸ]/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Combining Diacritical Marks
  str = str.replace(/[\u0300\u0301\u0303\u0309\u0323]/g, ''); // huyền, sắc, hỏi, ngã, nặng
  // eslint-disable-next-line no-misleading-character-class
  str = str.replace(/[\u02C6\u0306\u031B]/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)

  return str;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uniqueArray = (arr: Array<any> = []) => {
  const uniqueIds = new Set();

  const unique = arr.filter((element) => {
    const isDuplicate = uniqueIds.has(element._id);
    uniqueIds.add(element._id);
    if (!isDuplicate) {
      return true;
    }
    return false;
  });

  return unique;
};

export const generateLetterAvatar = (name = '') =>
  `${name.split(' ')[0] ? name.split(' ')[0][0] : ''}${
    name.split(' ')[1] ? name.split(' ')[1][0] : ''
  }`;

export const formatCategories = (
  categories: DefaultCmsDataResponse<Category>[]
) => {
  const postCategoriesData: FormatCategory[] = [];
  categories.map((item) => {
    postCategoriesData.push({
      id: item.id,
      name: item.attributes.name,
      slug: item.attributes.slug,
    });
  });
  return postCategoriesData;
};

export const formatPostTags = (tags: DefaultCmsDataResponse<PostTag>[]) => {
  const postTagsData: FormatPostTag[] = [];
  tags?.map((item) => {
    postTagsData.push({
      id: item.id,
      name: item.attributes.name,
      slug: item.attributes.slug,
    });
  });
  return postTagsData;
};

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === '[object regexp]'
    ) {
      return this.replace(str, `${newStr}`);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), `${newStr}`);
  };
}

export const formatPostStr = (str: string) => {
  let formattedStr = str;

  ['&#038;', '&#8221', '&#8220;', '&#8211;', '&#8217;'].forEach((key) => {
    formattedStr = formattedStr.replaceAll(key, '');
  });
  return formattedStr;
};

export const objectId = () => {
  const length = 16;
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const logger = (error: AxiosError) => {
  if (!error || process.env.NEXT_PUBLIC_ENABLE_TELEGRAM_LOGGER !== 'true')
    return;

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const msg = `‼️ Error ${error.response?.status?.toString()} ${error.config?.method?.toUpperCase()} ${
    error.config?.url
  } - ${dayjs(new Date())
    .tz('Asia/Ho_Chi_Minh')
    .format('dddd, MMMM D, YYYY h:mm A')}`;

  console.log(msg);

  sendTelegramMessage(msg);
};

export function removeInlineFontFamily(input = ''): string {
  if (!input) return '';

  const regex = /style="(.*?)"/g;

  // Replace all inline style occurrences with an empty string
  const modifiedString = input.replace(regex, (match, style) => {
    // Remove the font-family property from the inline style
    // eslint-disable-next-line regexp/optimal-quantifier-concatenation
    const newStyle = style.replace(/font-family\s*:\s*[^;]+;?/g, '');
    return `style="${newStyle}"`;
  });

  return modifiedString;
}
export const generateDeepLinkByPathname = (pathname: string) => {
  if (pathname.includes('aha-truck/price')) {
    return 'https://ahamove.onelink.me/fmLb/dq04k5xx';
  } else if (pathname.includes('aha-truck')) {
    return 'https://ahamove.onelink.me/fmLb/cbx4seed';
  }
  return null;
};

export function decodeHtmlEntities(input: string) {
  // const doc = new DOMParser().parseFromString(input, 'text/html');
  // return doc.body.textContent || '';

  // DOMParser is not supported in server side rendering
  // so we use 'he' library instead
  return input ? he.decode(input) : '';
}

export const extractHelpCenterToFAQPageJsonLd = (
  data: DefaultCmsDataResponse<HelpCenter | FormatHelpCenter | FormatPolicy>[]
): Question[] => {
  try {
    const result: Question[] = [];
    data.forEach((group) => {
      const items = group.attributes.items;
      items.forEach((item: HelpCenterItem | FormatRichDescription) => {
        if ('items' in item) {
          item.items.forEach((subItem: RichDescription) => {
            result.push({
              questionName: subItem.label,
              acceptedAnswerText: decodeHtmlEntities(subItem.value),
            } as Question);
          });
        } else {
          result.push({
            questionName: item.label,
            acceptedAnswerText: decodeHtmlEntities(item.value),
          } as Question);
        }
      });
    });

    return result;
  } catch (error) {
    return [];
  }
};
